import { lazy, Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import LoadingScreen from '../components/LoadingScreen';
import { PATH_AFTER_LOGIN } from '../config';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// ----------------------------------------------------------------------
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

// blog

const BookList = Loadable(lazy(() => import('../pages/audiobook/book/BookList')));
const BookCreate = Loadable(lazy(() => import('../pages/audiobook/book/BookCreate')));
const BookAudioList = Loadable(lazy(() => import('../pages/audiobook/bookAudio/BookAudioList')));
const BookAudioCreate = Loadable(lazy(() => import('../pages/audiobook/bookAudio/BookAudioCreate')));
const SliderCreate = Loadable(lazy(() => import('../pages/audiobook/slider/SliderCreate')));
const SliderList = Loadable(lazy(() => import('../pages/audiobook/slider/SliderList')));
const SettingList = Loadable(lazy(() => import('../pages/audiobook/setting/SettingList')));
const SettingCreate = Loadable(lazy(() => import('../pages/audiobook/setting/SettingCreate')));

const booksRoute = {
  path: 'books',
  element: (
    <RoleBasedGuard accessibleRoles={['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_EDITOR']}>
      <Outlet />
    </RoleBasedGuard>
  ),
  children: [
    { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },

    { path: 'book/new', element: <BookCreate /> },
    { path: 'books', element: <BookList /> },
    { path: 'book/:id/edit', element: <BookCreate /> },
    { path: 'book/:id/view', element: <BookCreate /> },

    { path: 'book-audio/new', element: <BookAudioCreate /> },
    { path: 'book-audios', element: <BookAudioList /> },
    { path: 'book-audio/:id/edit', element: <BookAudioCreate /> },
    { path: 'book-audio/:id/view', element: <BookAudioCreate /> },

    { path: 'slider/new', element: <SliderCreate /> },
    { path: 'sliders', element: <SliderList /> },
    { path: 'slider/:id/edit', element: <SliderCreate /> },
    { path: 'slider/:id/view', element: <SliderCreate /> },

    { path: 'setting/new', element: <SettingCreate /> },
    { path: 'settings', element: <SettingList /> },
    { path: 'setting/:id/edit', element: <SettingCreate /> },
    { path: 'setting/:id/view', element: <SettingCreate /> }


  ]
};

export default booksRoute;
