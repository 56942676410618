import { handleRequest } from '../../utils/axios';


export const findBlogForumPostsAPI = async (data) => {
  const config = {
    url: '/forum-post/search',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const getBlogForumPostByIdAPI = async (id) => {
  const config = {
    url: `/forum-post/${id}`,
    method: 'GET'
  };
  return handleRequest(config);
};

export const createBlogForumPostsAPI = async (data) => {
  const config = {
    url: '/forum-post/',
    method: 'POST',
    data
  };

  return handleRequest(config);
};

export const updateBlogForumPostsAPI = async (data) => {
  const config = {
    url: '/forum-post/',
    method: 'PUT',
    data
  };

  return handleRequest(config);
};

export const deleteBlogForumPostAPI = async (id) => {
  const config = {
    url: `/forum-post/${id}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};

export const deleteBlogForumPostsAPI = async (ids) => {
  const config = {
    url: `/forum-post/all/${ids.toString()}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};
