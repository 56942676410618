
import { handleRequest } from '../../utils/axios';

export const findBlogMembershipsAPI = async (data) => {
  const config = {
    url: '/membership/search',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const getBlogMembershipByIdAPI = async (id) => {
  const config = {
    url: `/membership/${id}`,
    method: 'GET'
  };
  return handleRequest(config);
};

export const createBlogMembershipAPI = async (data) => {
  const config = {
    url: '/membership/',
    method: 'POST',
    data
  };

  return handleRequest(config);
};

export const updateBlogMembershipAPI = async (data) => {
  const config = {
    url: '/membership/',
    method: 'PUT',
    data
  };

  return handleRequest(config);
};

export const deleteBlogMembershipAPI = async (id) => {
  const config = {
    url: `/membership/${id}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};

export const deleteBlogMembershipsAPI = async (ids) => {
  const config = {
    url: `/membership/all/${ids.toString()}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};
