import { Autocomplete, Stack, TextField } from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { TENANT_OPTIONS } from '../../../config';
import useAuth from '../../../hooks/useAuth';

export default function LoginFormFirebase() {
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    signInSuccessUrl: "/",
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        requireDisplayName: true,
        forceSameDevice: true
      },
      {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        // Invisible reCAPTCHA with image challenge and bottom left badge.
        recaptchaParameters: {
          type: 'image',
          size: 'invisible',
          badge: 'bottomleft'
        },
        defaultCountry: 'VN'
      },
    ],
    tosUrl: 'https://jmaster.io/terms',
    privacyPolicyUrl: "https://jmaster.io/privacy"
  };

  const { tenant, setTenant } = useAuth();

  const onChangeStatus = (newValue) => {
    if (newValue) setTenant(newValue);
  };

  return <Stack spacing={2} alignItems={"center"}>
    <Autocomplete
      sx={{ width: 360 }}
      onChange={(_, newValue) => onChangeStatus(newValue)}
      options={TENANT_OPTIONS}
      getOptionLabel={(option) => option}
      isOptionEqualToValue={(option, value) => option === value}
      value={tenant}
      renderInput={(params) => (
        <TextField label={"Tenants"} {...params} />
      )}
    />
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth(firebase.app(tenant))} />
  </Stack>;
}