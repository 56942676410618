import { handleRequest } from "../../utils/axios";

export const findBlogTagsAPI = async (data) => {
  const config = {
    url: '/tag/search',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const getBlogTagByIdAPI = async (id) => {
  const config = {
    url: `/tag/${id}`,
    method: 'GET'
  };
  return handleRequest(config);
};

export const createBlogTagAPI = async (data) => {
  const config = {
    url: '/tag/',
    method: 'POST',
    maxBodyLength: Infinity,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data
  };

  return handleRequest(config);
};

export const updateBlogTagAPI = async (data) => {
  const config = {
    url: '/tag/',
    method: 'PUT',
    maxBodyLength: Infinity,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data
  };

  return handleRequest(config);
};

export const deleteBlogTagAPI = async (id) => {
  const config = {
    url: `/tag/${id}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};

export const deleteBlogTagsAPI = async (ids) => {
  const config = {
    url: `/tag/all/${ids.toString()}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};
