import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import blogPostFavoriteReducer from './slices/blog/blog.bookmark';
import blogCacheReducer from './slices/blog/blog.cache';
import blogCommentReducer from './slices/blog/blog.comment';
import blogForumPostReducer from './slices/blog/blog.forumPost';
import blogMembershipReducer from './slices/blog/blog.membership';
import blogPostReducer from './slices/blog/blog.post';
import blogPrivilegeReducer from './slices/blog/blog.privilege';
import blogQuestionReducer from './slices/blog/blog.question';
import blogReviewReducer from './slices/blog/blog.review';
import blogRoleReducer from './slices/blog/blog.role';
import blogSeriesReducer from './slices/blog/blog.series';
import blogShortsReducer from './slices/blog/blog.shorts';
import blogTagReducer from './slices/blog/blog.tag';
import blogUserReducer from './slices/blog/blog.user';
import blogUserActivitiesReducer from './slices/blog/blog.userActivity';

import bookReducer from './slices/audiobook/book.book';
import bookAudioReducer from './slices/audiobook/book.bookAudio';
import settingReducer from './slices/audiobook/book.setting';
import sliderReducer from './slices/audiobook/book.slider';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error']
};

const rootReducer = combineReducers({
  blogPost: persistReducer({ ...rootPersistConfig, key: "blog-post" }, blogPostReducer),
  blogShorts: persistReducer({ ...rootPersistConfig, key: "blog-shorts" }, blogShortsReducer),
  blogForumPost: persistReducer({ ...rootPersistConfig, key: "blog-forum-post" }, blogForumPostReducer),
  blogPostFavorite: persistReducer({ ...rootPersistConfig, key: "blog-post-favorite" }, blogPostFavoriteReducer),
  blogTag: persistReducer({ ...rootPersistConfig, key: "blog-tag" }, blogTagReducer),
  blogComment: persistReducer({ ...rootPersistConfig, key: "blog-comment" }, blogCommentReducer),
  blogQuestion: persistReducer({ ...rootPersistConfig, key: "blog-question" }, blogQuestionReducer),
  blogSeries: persistReducer({ ...rootPersistConfig, key: "blog-series" }, blogSeriesReducer),
  mediaPrivilege: persistReducer({ ...rootPersistConfig, key: "blog-privilege" }, blogPrivilegeReducer),
  mediaRole: persistReducer({ ...rootPersistConfig, key: "blog-role" }, blogRoleReducer),
  mediaUser: persistReducer({ ...rootPersistConfig, key: "blog-user" }, blogUserReducer),
  blogMembership: persistReducer({ ...rootPersistConfig, key: "blog-membership" }, blogMembershipReducer),
  blogCache: persistReducer({ ...rootPersistConfig, key: "blog-cache" }, blogCacheReducer),
  blogReview: persistReducer({ ...rootPersistConfig, key: "blog-review" }, blogReviewReducer),
  userActivity: persistReducer({ ...rootPersistConfig, key: "blog-user-activities" }, blogUserActivitiesReducer),

  // book
  book: persistReducer({ ...rootPersistConfig, key: 'book-book' }, bookReducer),
  bookAudio: persistReducer({ ...rootPersistConfig, key: 'book-book-audio' }, bookAudioReducer),
  slider: persistReducer({ ...rootPersistConfig, key: "book-slider" }, sliderReducer),
  setting: persistReducer({ ...rootPersistConfig, key: 'book-setting' }, settingReducer),
});

export { rootPersistConfig, rootReducer };

