import { handleRequest } from '../../utils/axios';

export const findBlogShortsAPI = async (data) => {
  const config = {
    url: '/short-video/search',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const getBlogShortsByIdAPI = async (id) => {
  const config = {
    url: `/short-video/${id}`,
    method: 'GET'
  };
  return handleRequest(config);
};

export const createBlogShortsAPI = async (data) => {
  const config = {
    url: '/short-video/',
    method: 'POST',
    data
  };

  return handleRequest(config);
};

export const updateBlogShortsAPI = async (data) => {
  const config = {
    url: '/short-video/',
    method: 'PUT',
    data
  };

  return handleRequest(config);
};

export const deleteBlogShortAPI = async (id) => {
  const config = {
    url: `/short-video/${id}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};

export const deleteBlogShortsAPI = async (ids) => {
  const config = {
    url: `/short-video/all/${ids.toString()}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};
