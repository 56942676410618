import { handleRequest } from '../../utils/axios';
import { requestUploadImage } from '../uploadfile.service';

export const findBlogPostsAPI = async (data) => {
  const config = {
    url: '/post/search',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const getBlogPostByIdAPI = async (id) => {
  const config = {
    url: `/post/${id}`,
    method: 'GET'
  };
  return handleRequest(config);
};

export const createBlogPostAPI = async (data) => {
  const { fileObj, ...other } = data;
  let featureImage = null;

  const now = new Date();
  const path = `blog/${now.getFullYear()}/${now.getMonth()}/`;

  const resp = await requestUploadImage(fileObj, path);
  if (resp.code === '200')
    featureImage = resp.data;
  else return resp;

  const config = {
    url: '/post/',
    method: 'POST',
    data: {
      ...other,
      featureImage
    }
  };

  return handleRequest(config);
};

export const updateBlogPostAPI = async (data) => {
  const { fileObj, ...other } = data;

  let featureImage = other?.featureImage || null;

  if (fileObj.name) {
    const now = new Date();
    const path = `blog/${now.getFullYear()}/${now.getMonth()}/`;

    const resp = await requestUploadImage(fileObj, path);
    if (resp.code === '200')
      featureImage = resp.data;
    else return resp;
  }

  const config = {
    url: '/post/',
    method: 'PUT',
    data: {
      ...other,
      featureImage
    }
  };

  return handleRequest(config);
};

export const deleteBlogPostAPI = async (id) => {
  const config = {
    url: `/post/${id}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};

export const getPostBySlugAPI = async (slug) => {
  const config = {
    url: `/post/slug/${slug}`,
    method: 'GET'
  };
  return handleRequest(config);
};

export const deleteBlogPostsAPI = async (ids) => {
  const config = {
    url: `/post/all/${ids.toString()}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};
