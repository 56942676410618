// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------
export const TENANT_OPTIONS = process.env.REACT_APP_FIREBASE_NAME.split(',');

export const FIREBASE_APIS = () => TENANT_OPTIONS.map((name, index) => (
  {
    name,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY.split(',')[index],
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN.split(',')[index],
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL.split(',')[index],
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID.split(',')[index],
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET.split(',')[index],
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID.split(',')[index],
    appId: process.env.REACT_APP_FIREBASE_APPID.split(',')[index],
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID.split(',')[index]
  }
));

export const DEFAULT_TENANT = process.env.REACT_APP_DEFAULT_TENANT;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.dashboard.analytics; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
};

export const API_BASE_URL = process.env.REACT_APP_BASE_URL;
