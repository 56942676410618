
import { handleRequest } from '../../utils/axios';

export const findBlogCommentsAPI = async (data) => {
  const config = {
    url: '/comment/search',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const getBlogCommentByIdAPI = async (id) => {
  const config = {
    url: `/comment/${id}`,
    method: 'GET'
  };
  return handleRequest(config);
};

export const createBlogCommentAPI = async (data) => {
  const config = {
    url: '/comment/',
    method: 'POST',
    data
  };

  return handleRequest(config);
};

export const updateBlogCommentAPI = async (data) => {
  const config = {
    url: '/comment/',
    method: 'PUT',
    data
  };

  return handleRequest(config);
};

export const deleteBlogCommentAPI = async (id) => {
  const config = {
    url: `/comment/${id}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};

export const deleteBlogCommentsAPI = async (ids) => {
  const config = {
    url: `/comment/all/${ids.toString()}`,
    method: 'DELETE'
  };
  return handleRequest(config);
}

