import { createSlice } from '@reduxjs/toolkit';
import { findBookAPI, getBookByIdAPI } from '../../../service/audiobook/book.book.service';
// utils

const initialState = {
  isLoading: false,
  error: null,
  totalElements: 0,
  totalPages: 0,
  numberOfElements: 0,
  books: [],
  book: null,
  search: {
    page: 0,
    size: 10,
    value: '',
    id: 0,
    orders: [
      {
        order: 'asc',
        property: 'createdAt',
      },
    ],
    filterBys: {
      status: null,
      tagIds: null,
      uid: null,
      cateId: null
    }
  },
};

const slice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setBooks(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.books = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setBook(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.book = response.data;
    },
    setBookSearch(state, action) {
      state.isLoading = false;
      state.search = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const { setBookSearch } = slice.actions;
// ----------------------------------------------------------------------

export function getBooks() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    // read state from rootReducer
    const { book } = getState();
    const resp = await findBookAPI({ ...book.search, value: `%${book.search.value}%` });

    if (resp.code === '200') dispatch(slice.actions.setBooks(resp));
    else dispatch(slice.actions.hasError(resp));
  };
}

export function getBook(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const resp = await getBookByIdAPI(id);
    if (resp.code === '200') dispatch(slice.actions.setBook(resp));
    else dispatch(slice.actions.hasError(resp));
  };
}
