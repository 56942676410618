
import { handleRequest } from '../../utils/axios';

export const findBlogReviewsAPI = async (data) => {
  const config = {
    url: '/review/search',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const getBlogReviewByIdAPI = async (id) => {
  const config = {
    url: `/review/${id}`,
    method: 'GET'
  };
  return handleRequest(config);
};

export const createBlogReviewAPI = async (data) => {
  const config = {
    url: '/review/',
    method: 'POST',
    data
  };

  return handleRequest(config);
};

export const updateBlogReviewAPI = async (data) => {
  const config = {
    url: '/comment/',
    method: 'PUT',
    data
  };

  return handleRequest(config);
};

export const deleteBlogReviewAPI = async (id) => {
  const config = {
    url: `/review/${id}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};

export const deleteBlogReviewsAPI = async (ids) => {
  const config = {
    url: `/review/all/${ids.toString()}`,
    method: 'DELETE'
  };
  return handleRequest(config);
}

