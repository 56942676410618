import { lazy, Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// ----------------------------------------------------------------------
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

// blog

const BlogTagCreate = Loadable(lazy(() => import('../pages/blog/tag/BlogTagCreate')));
const BlogTagList = Loadable(lazy(() => import('../pages/blog/tag/BlogTagList')));
const BlogCommentList = Loadable(lazy(() => import('../pages/blog/comment/BlogCommentList')));
const BlogCommentCreate = Loadable(lazy(() => import('../pages/blog/comment/BlogCommentCreate')));
const BlogReviewList = Loadable(lazy(() => import('../pages/blog/review/BlogReviewList')));
const BlogReviewCreate = Loadable(lazy(() => import('../pages/blog/review/BlogReviewCreate')));
const BlogPostList = Loadable(lazy(() => import('../pages/blog/post/BlogPostList')));
const BlogPostCreate = Loadable(lazy(() => import('../pages/blog/post/BlogPostCreate')));
const BlogQuestionList = Loadable(lazy(() => import('../pages/blog/question/BlogQuestionList')));
const BlogQuestionCreate = Loadable(lazy(() => import('../pages/blog/question/BlogQuestionCreate')));
const BlogForumPostList = Loadable(lazy(() => import('../pages/blog/forum-post/BlogForumPostList')));
const BlogForumPostCreate = Loadable(lazy(() => import('../pages/blog/forum-post/BlogForumPostCreate')));
const BlogPostFavoriteList = Loadable(lazy(() => import('../pages/blog/post/BlogPostFavoriteList')));
const BlogSeriesCreate = Loadable(lazy(() => import('../pages/blog/series/BlogSeriesCreate')));
const BlogSeriesList = Loadable(lazy(() => import('../pages/blog/series/BlogSeriesList')));
const BlogRoleCreate = Loadable(lazy(() => import('../pages/blog/role/BlogRoleCreate')));
const BlogRoleList = Loadable(lazy(() => import('../pages/blog/role/BlogRoleList')));
const BlogPrivilegeCreate = Loadable(lazy(() => import('../pages/blog/privilege/BlogPrivilegeCreate')));
const BlogPrivilegeList = Loadable(lazy(() => import('../pages/blog/privilege/BlogPrivilegeList')));
const UserList = Loadable(lazy(() => import('../pages/blog/user/UserList')));
const UserEdit = Loadable(lazy(() => import('../pages/blog/user/UserEdit')));
const BlogMembershipList = Loadable(lazy(() => import('../pages/blog/membership/BlogMembershipList')));
const BlogMembershipCreate = Loadable(lazy(() => import('../pages/blog/membership/BlogMembershipCreate')));
const MediaCacheList = Loadable(lazy(() => import('../pages/blog/cache/MediaCacheList')));
const MediaCacheKeyList = Loadable(lazy(() => import('../pages/blog/cache/MediaCacheKeyList')));
const UserActivityList = Loadable(lazy(() => import('../pages/blog/userActivity/UserActivityList')));
const BlogShortsList = Loadable(lazy(() => import('../pages/blog/shorts/BlogShortsList')));
const BlogShortsCreate = Loadable(lazy(() => import('../pages/blog/shorts/BlogShortsCreate')));

const blogRoute = {
  path: 'blog',
  element: (
    <RoleBasedGuard accessibleRoles={['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_EDITOR']}>
      <Outlet />
    </RoleBasedGuard>
  ),
  children: [
    { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },

    { path: 'post/new', element: <BlogPostCreate /> },
    { path: 'posts', element: <BlogPostList /> },
    { path: 'post/:id/edit', element: <BlogPostCreate /> },
    { path: 'post/:id/view', element: <BlogPostCreate /> },

    { path: 'forum-post/new', element: <BlogForumPostCreate /> },
    { path: 'forum-posts', element: <BlogForumPostList /> },
    { path: 'forum-post/:id/edit', element: <BlogForumPostCreate /> },
    { path: 'forum-post/:id/view', element: <BlogForumPostCreate /> },

    { path: 'question/new', element: <BlogQuestionCreate /> },
    { path: 'questions', element: <BlogQuestionList /> },
    { path: 'question/:id/edit', element: <BlogQuestionCreate /> },
    { path: 'question/:id/view', element: <BlogQuestionCreate /> },

    { path: 'post-favorites', element: <BlogPostFavoriteList /> },

    { path: 'comments', element: <BlogCommentList /> },
    { path: 'comment/:id/edit', element: <BlogCommentCreate /> },

    { path: 'reviews', element: <BlogReviewList /> },
    { path: 'review/:id/edit', element: <BlogReviewCreate /> },

    { path: 'series/new', element: <BlogSeriesCreate /> },
    { path: 'seriess', element: <BlogSeriesList /> },
    { path: 'series/:id/edit', element: <BlogSeriesCreate /> },
    { path: 'series/:id/view', element: <BlogSeriesCreate /> },

    // tag
    { path: 'tag/new', element: <BlogTagCreate /> },
    { path: 'tags', element: <BlogTagList /> },
    { path: 'tag/:id/edit', element: <BlogTagCreate /> },
    { path: 'tag/:id/view', element: <BlogTagCreate /> },

    // shorts
    { path: 'shorts/new', element: <BlogShortsCreate /> },
    { path: 'shorts', element: <BlogShortsList /> },
    { path: 'shorts/:id/edit', element: <BlogShortsCreate /> },
    { path: 'shorts/:id/view', element: <BlogShortsCreate /> },

    // role
    { path: 'role/new', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><BlogRoleCreate /> </RoleBasedGuard> },
    { path: 'roles', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><BlogRoleList /> </RoleBasedGuard> },
    { path: 'role/:id/edit', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><BlogRoleCreate /> </RoleBasedGuard> },
    { path: 'role/:id/view', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><BlogRoleCreate /></RoleBasedGuard> },

    // privilege
    { path: 'privilege/new', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><BlogPrivilegeCreate /> </RoleBasedGuard> },
    { path: 'privileges', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><BlogPrivilegeList /> </RoleBasedGuard> },
    { path: 'privilege/:id/edit', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><BlogPrivilegeCreate /> </RoleBasedGuard> },
    { path: 'privilege/:id/view', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><BlogPrivilegeCreate /> </RoleBasedGuard> },

    // user
    { path: 'users', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><UserList /> </RoleBasedGuard> },
    { path: 'user/:id/view', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><UserEdit /> </RoleBasedGuard> },
    { path: 'user/:id/edit/role', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><UserEdit /> </RoleBasedGuard> },
    { path: 'user/:id/edit/email', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><UserEdit /> </RoleBasedGuard> },
    { path: 'user/:id/edit/phone', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><UserEdit /> </RoleBasedGuard> },
    { path: 'user/:id/edit/status', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><UserEdit /></RoleBasedGuard> },
    { path: 'user/:id/edit/uid', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><UserEdit /> </RoleBasedGuard> },
    { path: 'user/:id/edit/info', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><UserEdit /> </RoleBasedGuard> },

    // membership
    { path: 'membership/new', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><BlogMembershipCreate /> </RoleBasedGuard> },
    { path: 'memberships', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><BlogMembershipList /> </RoleBasedGuard> },
    { path: 'membership/:id/edit', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><BlogMembershipCreate /> </RoleBasedGuard> },
    { path: 'membership/:id/view', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><BlogMembershipCreate /> </RoleBasedGuard> },

    // cache
    { path: 'caches', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><MediaCacheList /></RoleBasedGuard> },
    { path: 'cache/:name/keys', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}><MediaCacheKeyList /> </RoleBasedGuard> },

    { path: 'user-activities', element: <RoleBasedGuard accessibleRoles={["ROLE_ADMIN"]}> <UserActivityList /> </RoleBasedGuard> },
  ]
};

export default blogRoute;
