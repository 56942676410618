import { createSlice } from '@reduxjs/toolkit';
import { findBlogPostFavoritesAPI } from '../../../service/blog/blog.postFavorite.service';
// utils

const initialState = {
  isLoading: false,
  error: null,
  totalElements: 0,
  totalPages: 0,
  numberOfElements: 0,
  postfavorites: [],
  postfavorite: null,
  search: {
    page: 0,
    size: 10,
    value: '',
    orders: [
      {
        order: "desc",
        property: 'createdAt'
      }
    ]
  }
};

const slice = createSlice({
  name: 'blogPostFavorite',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setPostFavorites(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.postfavorites = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setPostFavorite(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.postfavorite = response.data;
    },
    setBlogPostFavoriteSearch(state, action) {
      state.isLoading = false;
      state.search = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;
// Actions
export const { setBlogPostFavoriteSearch } = slice.actions;
// ----------------------------------------------------------------------

export function getBlogPostFavorites() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    // read state from rootReducer
    const { blogPostFavorite } = getState();

    const resp = await findBlogPostFavoritesAPI({ ...blogPostFavorite.search, value: `%${blogPostFavorite.search.value}%` });

    if (resp.code === '200')
      dispatch(slice.actions.setPostFavorites(resp));
    else
      dispatch(slice.actions.hasError(resp));
  };
}
