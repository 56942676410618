function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  profile: path(ROOTS_DASHBOARD, '/profile'),
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),

    newPost: path(ROOTS_DASHBOARD, '/blog/post/new'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),

    newForumPost: path(ROOTS_DASHBOARD, '/blog/forum-post/new'),
    forumPosts: path(ROOTS_DASHBOARD, '/blog/forum-posts'),

    newQuestion: path(ROOTS_DASHBOARD, '/blog/question/new'),
    questions: path(ROOTS_DASHBOARD, '/blog/questions'),

    newCategory: path(ROOTS_DASHBOARD, '/blog/category/new'),
    categories: path(ROOTS_DASHBOARD, '/blog/categories'),

    comments: path(ROOTS_DASHBOARD, '/blog/comments'),

    reviews: path(ROOTS_DASHBOARD, '/blog/reviews'),

    postFavorites: path(ROOTS_DASHBOARD, '/blog/post-favorites'),

    newSeries: path(ROOTS_DASHBOARD, '/blog/series/new'),
    seriess: path(ROOTS_DASHBOARD, '/blog/seriess'),

    newRole: path(ROOTS_DASHBOARD, '/blog/role/new'),
    roles: path(ROOTS_DASHBOARD, '/blog/roles'),

    newPrivilege: path(ROOTS_DASHBOARD, '/blog/privilege/new'),
    privileges: path(ROOTS_DASHBOARD, '/blog/privileges'),

    users: path(ROOTS_DASHBOARD, '/blog/users'),

    newTag: path(ROOTS_DASHBOARD, '/blog/tag/new'),
    tags: path(ROOTS_DASHBOARD, '/blog/tags'),

    newMembership: path(ROOTS_DASHBOARD, '/blog/membership/new'),
    memberships: path(ROOTS_DASHBOARD, '/blog/memberships'),

    newShorts: path(ROOTS_DASHBOARD, '/blog/shorts/new'),
    shorts: path(ROOTS_DASHBOARD, '/blog/shorts'),

    caches: path(ROOTS_DASHBOARD, '/blog/caches'),

    userActivities: path(ROOTS_DASHBOARD, '/blog/user-activities'),

  },
  dashboard: {
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
  },
  book: {
    root: path(ROOTS_DASHBOARD, '/books'),

    caches: path(ROOTS_DASHBOARD, '/books/caches'),

    newBook: path(ROOTS_DASHBOARD, '/books/book/new'),
    books: path(ROOTS_DASHBOARD, '/books/books'),

    newBookAudio: path(ROOTS_DASHBOARD, '/books/book-audio/new'),
    bookAudios: path(ROOTS_DASHBOARD, '/books/book-audios'),

    newSetting: path(ROOTS_DASHBOARD, '/books/setting/new'),
    settings: path(ROOTS_DASHBOARD, '/books/settings'),

    newSlider: path(ROOTS_DASHBOARD, '/books/slider/new'),
    sliders: path(ROOTS_DASHBOARD, '/books/sliders'),
  }
};

export const PATH_DOCS = 'https://jmaster.io';