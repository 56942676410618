import axios from 'axios';
import { handleRequest } from '../../utils/axios';

export const findBookAudioAPI = async (data) => {
  const config = {
    url: '/book-audio/search',
    method: 'POST',
    data,
  };
  return handleRequest(config);
};

export const getBookAudioByIdAPI = async (id) => {
  const config = {
    url: `/book-audio/${id}`,
    method: 'GET',
  };
  return handleRequest(config);
};

export const createBookAudioAPI = async (data) => {
  const config = {
    method: 'POST',
    url: '/book-audio/',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data
  };
  return handleRequest(config);
};

export const updateBookAudioAPI = async (data) => {
  const config = {
    method: 'PUT',
    url: '/book-audio/',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data
  };
  return handleRequest(config);
};

export const deleteBookAudioAPI = async (id) => {
  const config = {
    url: `/book-audio/${id}`,
    method: 'DELETE',
  };
  return handleRequest(config);
};

export const deleteBookAudiosAPI = async (ids) => {
  const config = {
    url: `/book-audio/all/${ids.toString()}`,
    method: 'DELETE',
  };
  return handleRequest(config);
};

export const downloadFileAPI = async (filename) => {
  const config = {
    url: `/media/download/${filename}`,
    method: 'GET',
    responseType: 'blob',
  };

  return axios(config);
};