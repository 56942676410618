import { createSlice } from '@reduxjs/toolkit';
import { findBlogMembershipsAPI, getBlogMembershipByIdAPI } from '../../../service/blog/blog.membership.service';
// utils

const initialState = {
  isLoading: false,
  error: null,
  totalElements: 0,
  totalPages: 0,
  numberOfElements: 0,
  memberships: [],
  membership: null,
  search: {
    page: 0,
    size: 10,
    value: '',
    orders: [
      {
        order: 'desc',
        property: 'createdAt',
      },
    ],
  },
};

const slice = createSlice({
  name: 'blogMembership',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setMemberships(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.memberships = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setMembership(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.membership = response.data;
    },
    setBlogMembershipSearch(state, action) {
      state.isLoading = false;
      state.search = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const { setBlogMembershipSearch } = slice.actions;
// ----------------------------------------------------------------------

export function getBlogMemberships() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    // read state from rootReducer
    const { blogMembership } = getState();

    const resp = await findBlogMembershipsAPI({ ...blogMembership.search, value: `%${blogMembership.search.value}%` });
    if (resp.code === '200') dispatch(slice.actions.setMemberships(resp));
    else dispatch(slice.actions.hasError(resp));
  };
}

export function getBlogMembership(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const resp = await getBlogMembershipByIdAPI(id);
    if (resp.code === '200') dispatch(slice.actions.setMemberships(resp));
    else dispatch(slice.actions.hasError(resp));
  };
}
