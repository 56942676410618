// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------
const getIcon = (name) => (
  <SvgIconStyle src={`${process.env.PUBLIC_URL}/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);
const ICONS = {
  blog: getIcon('ic_blog'),
  user: getIcon('ic_user'),
  analytics: getIcon('ic_analytics'),
  setting: getIcon('ic_cache'),
  other: getIcon('ic_other'),
  shorts: getIcon('ic_shorts'),
  book: getIcon('ic_book'),
};
const navConfig = [
  // GENERAL
  {
    subheader: 'menu.general',
    items: [
      {
        title: 'menu.analytics',
        path: PATH_DASHBOARD.dashboard.analytics, icon: ICONS.analytics,
        hasRoles: ["ROLE_ADMIN", 'ROLE_MANAGER', 'ROLE_EDITOR']
      }
    ],
  },
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'menu.management',
    items: [
      {
        title: 'menu.blog',
        icon: ICONS.blog,
        hasRoles: ["ROLE_ADMIN", 'ROLE_MANAGER', 'ROLE_EDITOR'],
        children: [
          { title: 'menu.post', path: PATH_DASHBOARD.blog.posts },
          { title: 'menu.series', path: PATH_DASHBOARD.blog.seriess },
          { title: 'menu.forumPost', path: PATH_DASHBOARD.blog.forumPosts },
          { title: 'menu.question', path: PATH_DASHBOARD.blog.questions },
          { title: 'menu.tag', path: PATH_DASHBOARD.blog.tags, hasRoles: ["ROLE_EDITOR"] }
        ],
      },
      {
        title: 'menu.book',
        path: PATH_DASHBOARD.book.books,
        icon: ICONS.book,
        hasRoles: ["ROLE_ADMIN", 'ROLE_MANAGER', 'ROLE_EDITOR'],
        children: [
          { title: 'menu.book', path: PATH_DASHBOARD.book.books, hasRoles: ["ROLE_ADMIN",'ROLE_MANAGER', 'ROLE_EDITOR'] },
          { title: 'menu.bookAudio', path: PATH_DASHBOARD.book.bookAudios, hasRoles: ["ROLE_ADMIN",'ROLE_MANAGER', 'ROLE_EDITOR'] },
        ],
      },
      {
        title: 'menu.shorts',
        icon: ICONS.shorts,
        hasRoles: ["ROLE_ADMIN", 'ROLE_MANAGER', 'ROLE_EDITOR'],
        children: [
          { title: 'menu.shorts', path: PATH_DASHBOARD.blog.shorts },
        ],
      },
      {
        title: 'menu.other',
        icon: ICONS.other,
        hasRoles: ["ROLE_MANAGER"],
        children: [
          { title: 'menu.tag', path: PATH_DASHBOARD.blog.tags },
          { title: 'menu.comment', path: PATH_DASHBOARD.blog.comments },
          { title: 'menu.review', path: PATH_DASHBOARD.blog.reviews },
          { title: 'menu.postFavorite', path: PATH_DASHBOARD.blog.postFavorites },
        ],
      },
    ],
  },
  {
    subheader: 'menu.user',
    items: [
      {
        title: 'menu.user',
        icon: ICONS.user,
        hasRoles: ["ROLE_ADMIN"],
        children: [
          { title: 'menu.user', path: PATH_DASHBOARD.blog.users },
          { title: 'menu.privilege', path: PATH_DASHBOARD.blog.privileges },
          { title: 'menu.role', path: PATH_DASHBOARD.blog.roles },
          { title: 'menu.userActivity', path: PATH_DASHBOARD.blog.userActivities },
          { title: 'menu.membership', path: PATH_DASHBOARD.blog.memberships },
        ],
      }
    ],
  },
  {
    subheader: 'menu.setting',
    items: [
      {
        title: 'menu.setting',
        icon: ICONS.setting,
        hasRoles: ["ROLE_ADMIN"],
        children: [
          { title: 'menu.cache', path: PATH_DASHBOARD.blog.caches },
          { title: 'menu.slider', path: PATH_DASHBOARD.book.sliders },
          { title: 'menu.setting', path: PATH_DASHBOARD.book.settings }
        ],
      }
    ],
  },
];
export default navConfig;
