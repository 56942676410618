import { handleRequest } from '../../utils/axios';

export const findBookAPI = async (data) => {
  const config = {
    url: '/book/search',
    method: 'POST',
    data,
  };
  return handleRequest(config);
};

export const getBookByIdAPI = async (id) => {
  const config = {
    url: `/book/${id}`,
    method: 'GET',
  };
  return handleRequest(config);
};

export const createBookAPI = async (data) => {
  const config = {
    method: 'POST',
    url: '/book/',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data
  };
  return handleRequest(config);
};

export const updateBookAPI = async (data) => {
  const config = {
    method: 'PUT',
    url: '/book/',
    maxBodyLength: Infinity,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data
  };
  return handleRequest(config);
};

export const deleteBookAPI = async (id) => {
  const config = {
    url: `/book/${id}`,
    method: 'DELETE',
  };
  return handleRequest(config);
};

export const deleteBooksAPI = async (ids) => {
  const config = {
    url: `/book/all/${ids.toString()}`,
    method: 'DELETE',
  };
  return handleRequest(config);
};
