import { handleRequest } from '../../utils/axios';

export const findBlogPostFavoritesAPI = async (data) => {
  const config = {
    url: '/bookmark/search',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const deleteBlogPostFavoriteAPI = async (id) => {
  const config = {
    url: `/bookmark/${id}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};

export const deleteBlogPostFavoritesAPI = async (ids) => {
  const config = {
    url: `/post-favorite/all/${ids.toString()}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};
