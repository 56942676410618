
import { handleRequest } from '../../utils/axios';

export const findBlogQuestionsAPI = async (data) => {
  const config = {
    url: '/question/search',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const getBlogQuestionByIdAPI = async (id) => {
  const config = {
    url: `/question/${id}`,
    method: 'GET'
  };
  return handleRequest(config);
};

export const createBlogQuestionAPI = async (data) => {
  const config = {
    url: '/question/',
    method: 'POST',
    data
  };

  return handleRequest(config);
};

export const updateBlogQuestionAPI = async (data) => {
  const config = {
    url: '/question/',
    method: 'PUT',
    data
  };

  return handleRequest(config);
};

export const deleteBlogQuestionAPI = async (id) => {
  const config = {
    url: `/question/${id}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};

export const deleteBlogQuestionsAPI = async (ids) => {
  const config = {
    url: `/question/all/${ids.toString()}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};
